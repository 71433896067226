import api from '../axios.service'

export default {
  get() {
    // const params = new URLSearchParams()
    // params.append('search', data.search)

    return api.get('/apiTransportType')
  },
  list(data) {
    // const params = new URLSearchParams()
    // params.append('search', data.search)

    return api.get(`transport_type/list?search=${data.search}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  apiTransportExpensePriceRate(amount, transportTypeID) {
    return api.get(`/apiTransportExpensePriceRate?amount=${amount}&transport_type_id=${transportTypeID}`)
  },
  show(data, id) {
    const params = new URLSearchParams()
    params.append('search', data.search)

    return api.get(`/transportType/${id}`)
  },
  post(data) {

    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    params.append('search', data.search)

    return api.post('transport_type/store',params,{})
  },
  put(data) {

    let getFormData = data

    let params = new FormData();
    params.append('_method','PUT')
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key) && getFormData[key]) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`transport_type/update/${data.transport_type_id}`,params,{})
  },
  delete(data) {
    let params = new FormData();
    // params.append('search', data.search)

    return api.delete(`transport_type/delete/${data.transport_type_id}`,params)
  },
  changeStatus(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method','PUT')
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    return api.post(`transport_type/updateStatus/${data.id}`,params)
  },
}
